import { Layout } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Layout)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9fee0;
  position: relative;
  .works {
    .works-title {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .index {
    font-weight: 700;
    color: #0d8802;
  }
  .title {
    font-weight: 700;
    color: #003c55;
  }
  .content {
    font-weight: 500;
    color: #003c55;
  }
  .animate-slideRight {
    animation: slideRight 1s ease-out forwards;
    opacity: 0;
  }
  .animate-slideRight-delay-1 {
    animation: slideRight 1s ease-out forwards;
    animation-delay: 1s;
    opacity: 0;
  }
  .animate-slideRight-delay-2 {
    animation: slideRight 1s ease-out forwards;
    animation-delay: 2s;
    opacity: 0;
  }
  @keyframes slideRight {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;
