import React from 'react';
import { Wrapper } from '@/modules/desktop/homepage/components/about-us.styled.tsx';
import AboutUsImg from '@/assets/graphics/Img_AboutUs.png';
import { useTranslation } from 'react-i18next';
export const AboutUs = () => {
  const { i18n, t } = useTranslation();

  return (
    <Wrapper className='flex flex-col items-center  max-[875px]:!h-fit' id='homepage-about-us'>
      <div className='flex justify-center items-center app-container gap-10 w-full mt-24 max-[875px]:flex-col max-[875px]:mt-24 max-[625px]:mt-16]'>
        <div className='w-[45%] max-[875px]:w-[80%]'>
          <img src={AboutUsImg} alt='Durian' className='w-[80%] max-[875px]:w-full h-auto' />
        </div>
        <div className='w-[45%] max-[1100px]:mt-[-14%] max-[875px]:mt-0 max-[875px]:w-full'>
          <div className='font-bold text-[92px] leading-[128px]  max-[1200px]:text-[72px] mt-4 max-[600px]:text-[40px]  max-[875px]:text-[#003C55]'>
            {t('text-2')}
          </div>
          <div className='font-bold text-[20px] max-[1200px]:text-[16px] mt-4 max-[600px]:text-[14px]  max-[875px]:text-[#003C55] max-w-[684px]'>
            {t('text-3')}
          </div>
          <div className='font-bold text-[20px] max-[1200px]:text-[16px] mt-4 max-[600px]:text-[14px]  max-[875px]:text-[#003C55] max-w-[684px]'>
            {t('text-4')}
          </div>
          <ul className='list-disc list-inside'>
            <li className='font-semibold text-[18px] max-[1200px]:text-[14px] mt-1 max-[600px]:text-[14px]  max-[875px]:text-[#003C55] max-w-[684px]'>
              {t('text-5')}
            </li>
            <li className='font-semibold text-[18px] max-[1200px]:text-[14px] mt-1 max-[600px]:text-[14px]  max-[875px]:text-[#003C55] max-w-[684px]'>
              {t('text-6')}
            </li>
          </ul>
          <div className='font-bold text-[20px] max-[1200px]:text-[16px] mt-4 max-[600px]:text-[14px]  max-[875px]:text-[#003C55] max-w-[684px]'>
            🌱📈💰 {t('text-8')} 🌿✨
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AboutUs;
