export const resources = {
  en: {
    translation: {
      'text-1': 'Discover the future of agriculture with blockchain',
      'text-2': 'About Us',
      'text-3': ` TON Farm revolutionizes Agriculture with Blockchain-based NFT solutions and Farming Game backed by Real World Assets.`,
      'text-4': 'Starting with Chapter 1 – Durian, we empower global users with:',
      'text-5': 'Ownership of Durian Trees through Durian NFTs, ensuring transparent and sustainable benefits.',
      'text-6': 'Play to earn Token Points with our easy - funny - money farming game.',
      'text-7': '',
      'text-8': 'Join us in revolutionizing Agriculture and enjoy the bounty of nature!',
      'text-9': 'How it works',
      'text-10': 'Purchase an NFT',
      'text-11': `Discover and purchase our Durian NFT.
Each NFT symbolizes a real durian tree that will be planted and nurtured on your behalf.
`,
      'text-12': 'Monitor Trees’ Growth',
      'text-13': `After purchasing an NFT, plant your durian tree in our app.
Monitor its growth through regular updates and receive tips on nurturing our tree.
`,
      'text-14': 'Harvest rewards',
      'text-15': `As your durian tree matures, it will yield durian fruit, which will then be sold. You can claim the revenue generated at the end of each period.
This innovative approach seamlessly integrates blockchain technology with real-life economic production, as your NFT represents ownership of an actual durian tree.
`,
      'text-16': 'NFT Details',
      'text-17': 'NFT title:',
      'text-18': 'Monthong',
      'text-19': 'NFT Price:',
      'text-20': `Blockchain Network:`,
      'text-21': `Our partners`,
      'text-22': `Discover the future of Agriculture with Blockchain`,
      'text-23': `Connect with us`,
      'text-24': `Buy NFT`,
      'text-25': 'Start now',
      'text-26': 'OPEN TELEGRAM APP',
      'text-27': 'Mint NFT',
      'text-28': 'Join Now'
    }
  },
  vn: {
    translation: {
      'text-1': 'Khai phóng tiềm năng ngành Nông nghiệp bằng công nghệ Blockchain',
      'text-2': 'Về Chúng Tôi',
      'text-3': `TON Farm tiên phong cách mạng hóa ngành Nông nghiệp bằng giải pháp NFT trên Blockchain và trò chơi Nông trại được tài trợ bởi tài sản thực.`,
      'text-4': 'Khởi đầu với Chương 1 – Sầu riêng, TON Farm trao quyền cho người dùng toàn cầu:',
      'text-5': 'Quyền sở hữu cây sầu riêng thông qua Durian NFTs, đảm bảo lợi ích minh bạch và bền vững.',
      'text-6': 'Tham gia trò chơi Nông trại để kiếm Token Points với lối chơi đơn giản, hấp dẫn và sinh lợi.',
      'text-7': '',
      'text-8':
        'Cùng TON Farm cách mạng hóa ngành nông nghiệp và tận hưởng những phần thưởng phong phú từ mẹ thiên nhiên!',
      'text-9': 'Cách thức mô hình NFT hoạt động?',
      'text-10': 'Mua NFT',
      'text-11': `Khám phá và mua Durian NFT.
Mỗi NFT đại diện cho một cây sầu riêng được trồng và chăm sóc thay cho bạn.
`,
      'text-12': 'Theo dõi tăng trưởng',
      'text-13': `Sau khi mua NFT, hãy trồng cây sầu riêng của bạn ở trong ứng dụng của TON Farm.
Theo dõi sự sinh trưởng thông qua những cập nhật thường xuyên và nhận được lời khuyên từ việc nuôi dưỡng cây.
`,
      'text-14': 'Thu hoạch phần thưởng',
      'text-15': `Với mỗi cây sầu riêng trưởng thành, bạn có thể nhận được doanh thu từ việc bán trái sầu riêng vào cuối mỗi mùa vụ.
      Mô hình này kết hợp công nghệ blockchain với sản xuất kinh tế thực tế, vì NFT của bạn đại diện cho một cây sầu riêng thật.
`,
      'text-16': 'Thông tin NFT',
      'text-17': 'Loại NFT:',
      'text-18': 'Monthong',
      'text-19': 'Giá NFT:',
      'text-20': `Mạng Blockchain:`,
      'text-21': `Đối tác của chúng tôi`,
      'text-22': `Khai phóng tiềm năng ngành Nông nghiệp bằng công nghệ Blockchain`,
      'text-23': `Kết nối với chúng tôi`,
      'text-24': `MUA NFT`,
      'text-25': 'MỞ ỨNG DỤNG TELEGRAM',
      'text-26': 'MỞ ỨNG DỤNG TELEGRAM',
      'text-27': 'MUA NFT',
      'text-28': 'Tham Gia Ngay'
    }
  },
  cn: {
    translation: {
      'text-1': '释放农业行业潜力，依托区块链技术',
      'text-2': '关于我们',
      'text-3': `TON Farm 领先于通过区块链上的NFT解决方案和由实物资产资助的农场游戏来革命化农业行业。`,
      'text-4': '从第一章——榴莲开始，TON Farm 为全球用户赋能：',
      'text-5': '通过 Durian NFTs 实现榴莲树的所有权，确保收益的透明性和可持续性。',
      'text-6': '参与农场游戏，利用简单、有趣且盈利的玩法赚取代币积分。',
      'text-7': '',
      'text-8': '加入 TON Farm，携手革新农业，尽享自然的丰盛馈赠！',
      'text-9': 'NFT 模型如何运作?',
      'text-10': '购买 NFT',
      'text-11': `发现并购买TON Farm的 Durian NFT。
每个NFT代表一棵真实的榴莲树。这些榴莲树将由农业工程师代为种植和照料，代表你。
`,
      'text-12': '监控树木的生长',
      'text-13': `购买NFT后，请在TON Farm的应用程序中种植你的榴莲树。
通过定期更新来监控树木的生长，并获得养护树木的简单建议。
`,
      'text-14': '收获',
      'text-15': `对于每棵成熟的榴莲树，你可以在每个收获季节结束时从销售榴莲中获得收入。
该模型将区块链技术与实际经济生产结合在一起，因为您的NFT代表一棵真实的榴莲树。
`,
      'text-16': 'NFT 详情',
      'text-17': 'NFT 标题：',
      'text-18': 'Monthong',
      'text-19': 'NFT 价格：',
      'text-20': `区块链网络：`,
      'text-21': `我们的合作伙伴`,
      'text-22': `释放农业行业潜力，依托区块链技术`,
      'text-23': `与我们联系`,
      'text-24': `购买 NFT`,
      'text-25': '打开 TELEGRAM 应用程序',
      'text-26': '打开 TELEGRAM 应用程序',
      'text-27': '购买 NFT',
      'text-28': '立即加入'
    }
  }
};
