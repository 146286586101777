import React from 'react';
import { Wrapper } from '@/modules/desktop/homepage/components/nft-working-process.styled.tsx';
import Durian from '@/assets/background/Durian.png';
import Fences from '@/assets/graphics/Fences.png';
import Step1 from '@/assets/graphics/Step1.png';
import Step2 from '@/assets/graphics/Step2.png';
import Step3 from '@/assets/graphics/Step3.png';
import Line1 from '@/assets/graphics/line.png';
import Line2 from '@/assets/graphics/line1.png';
import { useTranslation } from 'react-i18next';
export const NFTWorkingProcess = () => {
  const { i18n, t } = useTranslation();
  return (
    <Wrapper className='pt-[180px]' id='homepage-how-it-work'>
      <div className='flex justify-center items-center w-full relative flex-col'>
        <div className='flex justify-start flex-col app-container'>
          <div className='flex justify-start flex-col w-[58%] max-[768px]:w-[100%]'>
            <div className='font-bold text-[92px] text-[#003C55] max-[1200px]:text-[64px] max-[600px]:text-[40px]'>
              {t('text-9')}
            </div>
            {/* <div className='font-medium text-[20px] text-[#003C55] max-w-[620px]'>
              NFT owners can collect revenue generated from their durian tree based on its real-life production.
            </div> */}
            <div
              className='w-[144px] h-[48px] rounded-3xl bg-[#0D8802] flex justify-center items-center font-bold text-[16px] leading-[28px] mt-10 cursor-pointer'
              onClick={() => {
                window.open('https://www.canva.com/design/DAGKdUNtgGM/Vk146RI1bHNaPn-oRv_3FQ/view');
              }}
            >
              {t('text-28')}
            </div>
          </div>
        </div>
        <div className='w-[40%] max-w-[800px] absolute right-0 max-[768px]:relative max-[768px]:self-end max-[768px]:w-[80%] max-[768px]:max-w-[468px]'>
          <img src={Durian} alt='Durian' />
        </div>
      </div>
      <div className='relative mt-20 max-w-[1050px] px-4 flex flex-col max-[566px]:gap-[48px]'>
        <div className='works animate-slideRight flex items-center gap-[5%] max-[566px]:flex-col'>
          <img src={Step1} className='w-[35%] min-w-[232px]' alt='' />
          <div className='flex flex-col max-w-[60%] max-[566px]:w-full max-[566px]:max-w-[100%]'>
            <div className='works-title'>
              <div className='index text-[92px] max-[800px]:text-[64px] max-[675px]:text-[40px]'>1.</div>
              <div className='title text-[40px] max-[800px]:text-[32px] max-[675px]:text-[24px] '>{t('text-10')}</div>
            </div>
            <div className='content text-[20px] max-[800px]:text-[16px] max-[675px]:text-[14px]'>{t('text-11')}</div>
          </div>
        </div>
        <img src={Line1} className='w-full max-[566px]:hidden' alt='' />
        <div className='works animate-slideRight flex items-center gap-[5%] max-[566px]:flex-col-reverse'>
          <div className='flex flex-col max-w-[60%] max-[566px]:w-full max-[566px]:max-w-[100%]'>
            <div className='works-title'>
              <div className='index text-[92px] max-[800px]:text-[64px] max-[675px]:text-[40px]'>2.</div>
              <div className='title text-[40px] max-[800px]:text-[32px] max-[675px]:text-[24px]'>{t('text-12')}</div>
            </div>
            <div className='content text-[20px] max-[800px]:text-[16px] max-[675px]:text-[14px]'>{t('text-13')}</div>
          </div>
          <img src={Step2} className='w-[35%]  min-w-[232px]' alt='' />
        </div>
        <img src={Line2} className='w-full max-[566px]:hidden' alt='' />
        <div className='works animate-slideRight flex items-center gap-[5%] max-[566px]:flex-col'>
          <img src={Step3} className='w-[35%]  min-w-[232px]' alt='' />
          <div className='flex flex-col max-w-[60%] max-[566px]:w-full max-[566px]:max-w-[100%]'>
            <div className='works-title'>
              <div className='index text-[92px] max-[800px]:text-[64px] max-[675px]:text-[40px]'>3.</div>
              <div className='title text-[40px] max-[800px]:text-[32px] max-[675px]:text-[24px]'>{t('text-14')}</div>
            </div>
            <div className='content text-[20px] max-[800px]:text-[16px] max-[675px]:text-[14px]'>{t('text-15')}</div>
          </div>
        </div>
      </div>

      <div className='mt-32 mb-[320px] w-full max-[675px]:mb-[144px]'>
        <img className='w-full' src={Fences} alt='Fences' />
      </div>
    </Wrapper>
  );
};

export default NFTWorkingProcess;
