import styled from 'styled-components';
export const Wrapper = styled.div`
  width: 100vw;
  height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
`;
