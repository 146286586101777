import { Layout } from 'antd';
import styled from 'styled-components';
import BGBushes from '@/assets/background/Bushes.png';

export const Wrapper = styled(Layout)`
  background: url(${BGBushes});
  width: 100%;
  height: min(calc(100vw * 0.58), 881px);
  position: relative;
  background-size: 100% auto;
  margin-top: -10%;
  background-repeat: no-repeat;
  bottom: -100%;
  animation: slideUp 1s ease-out forwards;
  @keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
