import { Layout } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Layout)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #e9fee0;
`;
