import { Layout } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Layout)`
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .app-content {
    width: 100%;
  }
  .loading-state {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    background: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px);
    &.active-loading {
      opacity: 1;
      visibility: visible;
      transition: all 0.5s;
    }
  }
`;
