import { Layout } from 'antd';
import styled from 'styled-components';
import BGSky from '@/assets/background/Sky.png';

export const Wrapper = styled(Layout)`
  background: url(${BGSky});
  width: 100%;
  background-size: 100% 100%;
  position: relative;
  .animate-slideDown {
    animation: slideDown 1s ease-out forwards;
    animation-delay: 2s;
    opacity: 0;
  }

  .animate-slideUp {
    animation: slideUp 1s ease-out forwards;
    animation-delay: 1s;
    opacity: 0;
  }
  @keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
