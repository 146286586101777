import React, { PropsWithChildren, memo, useEffect, useState, Suspense } from 'react';
import { Layout as LayoutAnt } from 'antd';
import { Wrapper } from './layout.styled';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Loading from '../app-loading/app-loading';

const MobileHeader = React.lazy(() => import('../app-header/mobile/header.tsx'));
const DesktopHeader = React.lazy(() => import('../app-header/desktop/header.tsx'));
const DesktopFooter = React.lazy(() => import('../app-footer/desktop/footer.tsx'));

const { Content } = LayoutAnt;

interface ILayoutProps extends PropsWithChildren {
  className?: string;
  showStatusBar?: boolean;
  isLoadingComponent?: boolean;
}
const LayoutComponent = ({ children, className, isLoadingComponent }: ILayoutProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    setIsLoading(true);
    const onHidePreLoading = () => {
      try {
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      } catch (err: any) {
        toast.error(err?.message || err);
      }
    };
    const timer1 = setTimeout(onHidePreLoading, 0);
    return () => {
      clearTimeout(timer1);
    };
  }, [location?.pathname]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 675);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 675);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Wrapper>
      <Suspense fallback={<div>Loading...</div>}>{isMobile ? <MobileHeader /> : <DesktopHeader />}</Suspense>
      <Content className={`app-content  ${className}`}>{children}</Content>
      <Suspense fallback={<div>Loading...</div>}>
        {' '}
        <DesktopFooter />
      </Suspense>
      <div
        className={classNames(`loading-state`, {
          'active-loading': isLoading || isLoadingComponent
        })}
      >
        <Loading />
      </div>
    </Wrapper>
  );
};

export const Layout = memo(LayoutComponent);
