import React, { memo } from 'react';
import { Wrapper } from './app-loading.styled';

const LoadingComponent = () => {
  return (
    <Wrapper id='loading'>
      <div>LOADING...</div>
    </Wrapper>
  );
};

export const Loading = memo(LoadingComponent);

export default Loading;
