import React, { memo } from 'react';
import { Layout } from '@/components/app-layout/layout.tsx';
import { Wrapper } from '@/modules/desktop/homepage/homepage.styled.tsx';
import Discovery from '@/modules/desktop/homepage/components/discovery.tsx';
import NFTWorkingProcess from '@/modules/desktop/homepage/components/nft-working-process.tsx';
import NFTDetail from '@/modules/desktop/homepage/components/nft-detail.tsx';
import AboutUs from '@/modules/desktop/homepage/components/about-us.tsx';
const HomePageComponent = () => {
  return (
    <Layout>
      <Wrapper>
        <Discovery />
        <AboutUs />
        <NFTWorkingProcess />
        <NFTDetail />
        {/* <Partner /> */}
      </Wrapper>
    </Layout>
  );
};
export const HomePage = memo(HomePageComponent);
