import React from 'react';
import { Wrapper } from '@/modules/desktop/homepage/components/discovery.styled.tsx';
import BGFarm from '@/assets/background/Farm.png';
import { useTranslation } from 'react-i18next';

export const Discovery = () => {
  const { i18n, t } = useTranslation();
  return (
    <Wrapper className='flex flex-col items-center'>
      <div className='flex justify-center items-center flex-col w-full'>
        <div className='flex justify-center items-center flex-col animate-slideDown'>
          <div className='font-bold text-[92px] text-[#003C55] text-center mt-[110px] max-[675px]:mt-[82px] max-[675px]:text-[52px] max-[525px]:text-[40px] max-[1000px]:text-[64px] app-container'>
            {t('text-1')}
          </div>
          <div className='flex justify-center items-center gap-6 mt-10'>
            <div
              className='px-6 py-4 rounded-3xl bg-[#0D8802] flex justify-center items-center font-bold text-[16px] line-height-[28px] cursor-pointer'
              onClick={() => {
                window.open('https://www.canva.com/design/DAGKdUNtgGM/Vk146RI1bHNaPn-oRv_3FQ/view');
              }}
            >
              {t('text-28')}
            </div>
            {/* <div
              className='w-[128px] h-[48px] bg-[#FFFFFF] text-black rounded-3xl flex justify-center items-center font-bold text-[16px] line-height-[28px] border-2 border-[
#B3D8C6] cursor-pointer'
            >
              Mint NFT
            </div> */}
          </div>
        </div>
        <div className='w-full animate-slideUp'>
          <img className='w-full' src={BGFarm} alt='Farm background' />
        </div>
      </div>
    </Wrapper>
  );
};

export default Discovery;
